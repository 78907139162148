import { useLocation } from '@reach/router';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

/**
 * Custom hook to return GuestName
 * @return {string}
 */
function useGroupFarmasi() {
  const location = useLocation();
  const type = getQueryValue(location, 'group');

  return type === 'farmasi';
}

export default useGroupFarmasi;
